import React, { Component, Fragment, Suspense } from "react";
import { Switch, Route, Redirect,withRouter} from "react-router-dom";

import logo from "./logo.svg";
import "./App.css";
import Header from "./components/header";
import Sidenav from "./components/sidenav";
import Footer from "./components/footer";
import Login from "./components/login.component";
// import Dashboard from "./components/dashboard.component";
import FarmerList from "./components/farmerlist.component";
import CropsList from "./components/crops.component";
import CropsFarmersList from "./components/cropsNestedFarmerDetails.component";
import CropAnalytics from "./components/cropanalytics.component";
//import Agronomics from './components/agro.component';
import Agronomics from "./components/agronomic.activities.component";
// import Landholding from "./components/landholding.component";
import BreadCrumb from "./components/Breadcrumb.component";
import AuthService from "./services/auth.service";
import organization from './components/orgHierarchy';
import ProductData from "./components/inputproducts";
import inputOrders from "./components/inputOrder.component";

// import ProcurementList from "./components/procurements.component";

import ProcurementList from "./components/commodityprocurements.component";

import WareHouse from "./components/warehouse.component";
import FpoFarmer from "./components/fpofarmers.component";
import FpoLandholding from "./components/fpolandholdings.component";
import FpoHome from "./components/fpohome.component";
import FpoCrop from "./components/fpocrops.component";
import FpoInput from "./components/fpoinputs.component";
import FpoProcurement from "./components/fpoprocurements.component";
import FpoFarmerOrder from "./components/fpoorders.component";
import FpoWsp from "./components/fpowsp.component";
import FpoFI from "./components/fpofinances.component";
import FpoBO from "./components/fpobusiness.component";

import InputComponent from "./components/inputcomponents.component";
import BusinessFeed from "./components/businessfeed.component";
import FpoList from "./components/fpolist.component";
import profile from "./components/profile";
import compliancehistory from "./components/compliancehistory.component";
import modalbox from "./components/modalbox.js";
import message from "./components/message";
import VillageLevelFeed from "./components/vilagelevelfeed.component";
import ProcVillageLevel from "./components/ProcVillageLevel.js";
import BoProductInput from "./components/BoProductInput.js";
import BoFarmerProductInput from "./components/BoFarmerProductInput.js";
import webnotification from "./components/webnotification.component.js";
import farmerNotification from "./components/farmerNotification";
import inputBuyOrderNotification from "./components/inputBuyOrderNotification";
import  CommoditySellOrderNotification from "./components/CommoditySellOrderNotification" 
import Notification from "./components/Notification";
import Reminder from "./components/Reminder";
import SoilTesting from './components/SoilTesting';
import FpoBoList from './components/fpoBO.fpolist.component.js'
// import Landholding from "./components/landholding.component";
const Landholding = React.lazy(()=>import('./components/landholding.component'))
const LandholdingFpoWise = React.lazy(()=>import('./components/landholdingFPO.component.js'))
const Dashboard = React.lazy(()=>import("./components/dashboard.component"))

const items = [
  { to: "/dashboard", label: "Dashboard" },
  { to: "/farmerlist", label: "FarmerList" },
  { to: "/crops", label: "CropsList" },
  { to: "/landholding", label: "Landholding" },
  { to: "/cropsFarmersList", label: "CropsFarmers" },
  { to: "/cropsanalytics", label: "Crops Analytics" },
  { to: "/agronomics", label: "CropsFarmers" },
  // {to: '/organization',label:'organization'},
  { to: "/ProductData", label: "ProductData" },
  { to: "/commodities", label: "Procurements" },
  { to: "/wsp", label: "WSP" },
  { to: "/fpofarmers", label: "FpoFarmers" },
  { to: "/fpolandholdings", label: "FpoLandholdings" },
  { to: "/landholdingFPO", label: "LandholdingFpoWise" },
  { to: "/fpohome", label: "FpoHomeData" },
  { to: "/fpocrops", label: "FpoCrops" },
  { to: "/FpoBoList", label: "FpoBoList" },
];

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDashBoard: false,
      showFarmerList: false,
      currentUser: undefined,
      loggedIn: localStorage.getItem('user')
    };
  }
 
  
  componentDidMount() {
    const user = AuthService.getCurrentUser();
    if (user) {
      this.setState({
        currentUser: user,
        showDashBoard: true,
        showFarmerList: true,
        loggedIn: localStorage.getItem('user')
      });
    }
    
  }

  render() {
    const { loggedIn } = this.state;
   
    const ProtectedRoute = ({ component: Comp, path, ...rest }) => {
      const logged = localStorage.getItem('user')
      return (
        <Route
          path={path}
          {...rest}
          render={(props) => {
            return logged ? (
              <Comp {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: "/",
                  state: {
                    prevLocation: path,
                    error: "You need to login first!",
                  },
                }}
              />
            );
          }}
        />
      );
    };
    return (
      <div className="wrap">
        <Switch>
          {/* <Route exact path={["/", "/login"]} component={Login} /> */}
          <Route exact path="/" component={Login} />

          <Fragment>
         
            <Header  data={withRouter}/>
            <Sidenav />
            <div className="topEmptyContent wrap"></div>
            <ProtectedRoute
              path="/dashboard"
              // loggedIn={this.state.loggedIn}
              component={(props)=> <Suspense fallback="Loading"><Dashboard {...props}/></Suspense>}
            />
            {/* <Route exact path="/dashboard" component={Dashboard} /> */}
            <ProtectedRoute exact path="/farmerlist" component={FarmerList} />
            {/* <ProtectedRoute exact path="/farmerlist/:category/:tab/:search" component={FarmerList} /> */}
            {/* <ProtectedRoute exact path="/farmerlist?category=&search=" component={FarmerList} /> */}
            {/* <ProtectedRoute exact path="/farmerlist?:category&:tab&:search" component={FarmerList} /> */}


           

            <ProtectedRoute exact path="/crops" component={CropsList} />
            <ProtectedRoute
              exact
              path="/cropsFarmersList/:cropId/:season/:year/:isVerified/:cropName"
              component={CropsFarmersList}
            />
            <ProtectedRoute
              exact
              path="/cropsFarmersList/:fpoName/:cropId/:season/:year/:isVerified/:cropName"
              component={CropsFarmersList}
            />
            
            <ProtectedRoute
              exact
              path="/agronomics/:cropId/:cropName"
              component={Agronomics}
            />
            <ProtectedRoute
              exact
              path="/agronomics/:fpoName/:cropId/:cropName"
              component={Agronomics}
            />
            <ProtectedRoute
              exact
              path="/cropanalytics/:cropId/:cropName"
              component={CropAnalytics}
            />
            {/* <ProtectedRoute exact path="/landholding/:fpoName" component={Landholding} /> */}
            {/* <ProtectedRoute exact path="/landholding/" component={Landholding} />  */}
            <ProtectedRoute
              path="/landholding/"
              // loggedIn={this.state.loggedIn}
              component={(props)=> <Suspense fallback="Loading"><Landholding {...props}/></Suspense>}
            />
            <ProtectedRoute
              path="/landholdingFPO/:fpoName"
              // loggedIn={this.state.loggedIn}
              component={(props)=> <Suspense fallback="Loading"><LandholdingFpoWise {...props}/></Suspense>}
            />

            <ProtectedRoute exact path="/organization" component={organization} />
            <ProtectedRoute exact path="/inputs" component={ProductData} />

            <ProtectedRoute exact path="/inputorders" component={inputOrders} />

            <ProtectedRoute exact path="/commodities" component={ProcurementList} />
            <ProtectedRoute exact path="/wsp" component={WareHouse} />
            <ProtectedRoute exact path="/fpofarmers" component={FpoFarmer} />
            <ProtectedRoute exact path="/farmerlist/:fpoName" component={FarmerList} />

            <ProtectedRoute exact path="/fpolandholdings" component={FpoLandholding} />
            <ProtectedRoute exact path="/fpohomeData" component={FpoHome} />
            <ProtectedRoute exact path="/fpocrops" component={FpoCrop} />
            <ProtectedRoute exact path="/FpoBoList" component={FpoBoList} />
               <ProtectedRoute exact path="/crops/:fpoName" component={CropsList} />
            <ProtectedRoute exact path="/fpoinputs" component={FpoInput} />
            <ProtectedRoute exact path="/inputs/:fpoName" component={ProductData} />

            <ProtectedRoute exact path="/fpoprocurements" component={FpoProcurement} />
            <ProtectedRoute exact path="/commodities/:fpoName" component={ProcurementList} />

            <ProtectedRoute exact path="/fpoOrders" component={FpoFarmerOrder} />
            <ProtectedRoute exact path="/inputorders/:fpoName" component={inputOrders} />

            <ProtectedRoute exact path="/fpowsps" component={FpoWsp} />
            <ProtectedRoute exact path="/wsp/:fpoName" component={WareHouse} />
            <ProtectedRoute exact path="/fpoFinancials" component={FpoFI} />
            <ProtectedRoute exact path="/fpoBo/:fpoName" component={FpoBO} />


            <ProtectedRoute exact path="/fpolist" component={FpoList} />
            <ProtectedRoute
              exact
              path="/inputcomponent/:season/:year/:isVerified/:area/:cropId"
              component={InputComponent}
            />
            <ProtectedRoute
              exact
              path="/inputcomponent/:fpoName/:season/:year/:isVerified/:area/:cropId"
              component={InputComponent}
            />
            <ProtectedRoute exact path="/business-opportunity" component={BusinessFeed} />  
            <ProtectedRoute exact path="/business-opportunity/:fpoName" component={BusinessFeed} />
         

            <ProtectedRoute exact path="/villagelevelfeed/:category/:productName/:brand/:cattleType/:packetPrice/:totalPackets/:totalAmount" 
              component={VillageLevelFeed} />
                <ProtectedRoute exact path="/villagelevelfeed/:fpoName/:category/:productName/:brand/:cattleType/:packetPrice/:totalPackets/:totalAmount" 
              component={VillageLevelFeed} />
            <ProtectedRoute exact path="/userprofile" component={profile} />
            <ProtectedRoute exact path="/compliancehistory" component={compliancehistory} />
            {/* <ProtectedRoute exact path="/modalbox" component={modalbox} /> */}
            <ProtectedRoute exact path="/customized-sms/" component={message} />
            {/* <ProtectedRoute exact path="/ProcVillageLevel" component={ProcVillageLevel} /> */}
            <ProtectedRoute exact path="/TentativeVillageLevel/:cropName/:villageDate/:villageTentId/:villageTentSeason/:villageTentVerified" 
              component={ProcVillageLevel} />
                <ProtectedRoute exact path="/TentativeVillageLevel/:fpoName/:cropName/:villageDate/:villageTentId/:villageTentSeason/:villageTentVerified" 
              component={ProcVillageLevel} />




                          <ProtectedRoute exact path="/BoProductInput" component={BoProductInput} /> 
                          <ProtectedRoute exact path="/BoProductInput/:fpoName" component={BoProductInput} />
 
                          {/* <ProtectedRoute exact path="/BoFarmerProductInput" component={BoFarmerProductInput} />    */}
                          <ProtectedRoute
              exact
              path="/BoFarmerProductInput/:season/:year/:productId/:brandId"
              component={BoFarmerProductInput}
            />        
         
         <ProtectedRoute exact path="/webnotification/" component={webnotification} />
         <ProtectedRoute exact path="/Notification/" component={Notification} />
         <ProtectedRoute exact path="/farmerNotification" component={farmerNotification} />
         <ProtectedRoute exact path="/inputBuyOrderNotification" component={inputBuyOrderNotification} />
         <ProtectedRoute exact path="/CommoditySellOrderNotification" component={CommoditySellOrderNotification} />
         <ProtectedRoute exact path="/Reminder" component={Reminder} />
         <ProtectedRoute exact path="/SoilTesting/:farmerName/:siteId/:siteName" component={SoilTesting}/>
            {/* <Footer /> */}
          </Fragment>
        </Switch>
      </div>
    );
  }
}

export default App;
