import React, { Component } from "react";
import UserService from "../services/user.service";
import { TriggerAlert, AlertMessage } from "./dryfunctions";
import { Accordion, Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import reminderIcon from "../assets/img/reminderGreen.png";
import ReminderImg from "../assets/img/Reminder.jpg";
import { DateRangePicker } from "rsuite";
import subDays from "date-fns/subDays";
import "rsuite/dist/rsuite.css";
import Swal from 'sweetalert2';
class Reminder extends Component {
  constructor(props) {
    super(props);

    this.handleAllReadChange = this.handleAllReadChange.bind(this);

    this.state = {
      Reminderdata: [],
      totalCount: [],
      totalUnreadCount: [],
      selectedRange: "",
      startdate: null,
      enddate: null,
      showloader: true,
    };
  }

  navigateToPage = (pageName) => {
    const { isParentLogged } = this.state;
    if (isParentLogged) {
      if (pageName === "dashboard") {
        this.props.history.push("/" + pageName + "");
      }
    } else {
      this.props.history.push("/" + pageName + "");
    }
  };
  handleAllReadChange = (e) => { 
    let queryParam= this.props.location.search;
    let SplitqueryParam= queryParam.split("=");
    let SplitqueryParamTabValue= SplitqueryParam[1];
    UserService.MarkAsAllRead(SplitqueryParamTabValue).then(
      response => {
        Swal.fire({icon: 'success',title: 'Success',
        html: '<i style="color:red" class="fa fa-close"></i> <small>'+response.data.result.message+"</small>",
        showConfirmButton: false,timer: 5000,showCloseButton: true,})   
     },
    ) 
    window.location.reload();
  };
  sendNotificationLink = (link) => {
    this.props.history.push(`/${link}`);
    window.location.reload();
  };
  ReadStatus = (id) => {
    UserService.getChangeReadStatus(id).then((response) => {
      this.setState({
        id: response.data.result.id,
        isRead: true,
        bgColor: "#DCDCDC",
      });
      window.location.reload();
    });
  };

  componentDidMount() {
    var flag = false;
    const data = new FormData();
    data.append("month", 12);
    UserService.getRemindersList(data).then((response) => {
      this.setState({
        showloader: false,
        Reminderdata: response.data.data.result,
        totalCount: response.data.data.total_count,
        totalUnreadCount: response.data.data.total_unread_count,
        isRead: true,
        bgColor: "#DCDCDC",
      });
    });
  }

  handleChange = (range) => {
    const startDate = range[0]?.toISOString().substr(0, 10);
    const endDate = range[1]?.toISOString().substr(0, 10);
    this.setState({
      showloader: false,
      selectedRange: range,
      startdate: startDate,
      enddate: endDate,
    });
  };
  handleSubmit = (startDate, endDate) => {
    var flag = false;
    UserService.getRemindersList(startDate, endDate).then((response) => {
      this.setState({
        startdate: startDate,
        enddate: endDate,
        showloader: false,
        Reminderdata: response.data.data.result,
        totalCount: response.data.data.total_count,
        totalUnreadCount: response.data.data.total_unread_count,
        isRead: true,
        bgColor: "#DCDCDC",
      });
    });
    this.setState(
      {
        showloader: true,
      }
      );
  };

  render() {
    const { totalUnreadCount, totalCount, showloader } = this.state;

    const currentYear = new Date().getFullYear();
    const today = new Date().toISOString().substr(0, 10);
    const { allowedRange } = DateRangePicker;

    const ranges = [
      {
        label: "today",
        value: [new Date(), new Date()],
      },
      {
        label: "yesterday",
        value: [subDays(new Date(), 1), subDays(new Date(), 1)],
      },
    ];

    return (
      <div className="wrap dashBoardWrap">
        {showloader ? (
          <span className="spinner-border spinner-border-sm dashboardLoader"></span>
        ) : (
          <section
            className="mainWebContentSection "
            style={{ marginLeft: "25px" }}
          >
            <div className="breadcrumb pageBreadCrumbHolder">
              <a
                href="#"
                className="breadcrumb-item pageBreadCrumbItem"
                onClick={() => this.navigateToPage("dashboard")}
              >
                <FontAwesomeIcon
                  icon={faHome}
                  className="dvaraBrownText breadcrumb-separator pageBreadCrumbItem"
                  style={{ fontSize: "0.7rem" }}
                />
                &nbsp;Dashboard
              </a>
              <FontAwesomeIcon
                icon={faCaretRight}
                className="dvaraBrownText breadcrumb-separator pageBreadCrumbItem"
              />
              <a
                href="#"
                className="breadcrumb-item breadcrumbs__crumb breadcrumbs__crumb pageBreadCrumbItem"
                onClick={() => this.navigateToPage("webnotification")}
              >
                Web notification
              </a>
            </div>
            <div
              className="notification-box"
              style={{ height: "115x", width: "350px" }}>
              <img
                className="card main-icon dashBoardIcons"
                style={{
                  position: "absolute",
                  top: "1px",
                  left: "10px",
                  height: "110px",
                }}
                src={reminderIcon}
              />
              Reminder
              <div className="notification-box-mesage">
                Total Count ( {totalCount} ) <br />
                Unread Count ( {totalUnreadCount>0 ? totalUnreadCount : 0} ) <br />
                {totalUnreadCount>0 ? <div> Mark all as Read <input type="checkbox" onChange={this.handleAllReadChange}/></div>:''}
              </div>
            </div>
            <DateRangePicker
              style={{ marginLeft: "650px", marginTop: "-30px" }}
              onChange={this.handleChange}
              ranges={[]}
              value={this.state.selectedRange} cleanable={false}
              disabledDate={allowedRange(`${currentYear}-01-01`, new Date())}
            />
            <Button
              className="defaultButtonElem"
              style={{ marginLeft: "910px", marginTop: "-70px" }}
              onClick={() =>
                this.handleSubmit(this.state.startdate, this.state.enddate)
              }>
              Submit
            </Button>
            <div>              
              {Object.keys(this.state.Reminderdata).map((data, index, keys) => {
                return (
                  <div className="reminder-date">
                    <div className="Accordian-key">
                      <Card className="card-inner">
                        <div
                          style={{ color: "#212E3B", fontSize: "25px" }}
                          className="dvaraBrownText accordIcon accordionTitle personalInfo collapsed adding-Icon"
                        >
                          Reminders (Selected Date :{" "}
                            {this.state.selectedRange.length >0 ?
                            <span> 
                              ({this.state.selectedRange &&
                              moment(this.state.selectedRange[0]).format("DD/MM/YYYY")}{" "}
                            -{" "}
                            {this.state.selectedRange &&
                              moment(this.state.selectedRange[1]).format("DD/MM/YYYY")})</span> : " Select " })
                            </div>
                            <div>                                                  
                            <Card.Body style={{ display: "inline-block"}}>                             
                                <div>                                
                                  {Object.values(this.state.Reminderdata).map(
                                    (item, ind, values) => {
                                      return (
                                        <div>   
                                           {showloader ? (
                                            <span className="spinner-border spinner-border-sm dashboardLoader"></span>
                                              ) : (
                                            <section>                                                                      
                                          {item.length > 0 ? (
                                            <div>                                             
                                              {item.map((messagedata) => {
                                                return (
                                                  <div>                                                                                                        
                                                    {index === ind ? (
                                                      <div className="notification"
                                                        style={{background:
                                                            messagedata.is_read === true && messagedata.id === messagedata.id &&
                                                            this.state.bgColor}}
                                                        onClick={() =>this.ReadStatus(messagedata.id)} >
                                                        <img className="card main-icon dashBoardIcons"
                                                          style={{position: "absolute",
                                                            top: "7px",
                                                            left: "10px",
                                                            height: "65px",
                                                            width: "80px",
                                                            background:"rgb(229, 231, 233 )"}}
                                                          src={ReminderImg}
                                                        />
                                                        {messagedata.title}
                                                        <span className="date-time">
                                                          {moment(messagedata.created_at).format("DD/MM/YYYY , h:mm a")}
                                                        </span>
                                                        <div className="reminder-message-box">
                                                          <a href="#" onClick={() =>this.sendNotificationLink(messagedata.redirect_url)}>
                                                            {" "}{messagedata.message}.</a>
                                                        </div>
                                                      </div>
                                                    ) : ("")}                                                                                                    
                                                  </div>
                                                );
                                              })}                                              
                                            </div>
                                           ) : (
                                           <div style={{fontSize:"25px" }}>
                                              <b>No Reminders</b>
                                            </div>                                            
                                          )}                                         
                                       </section>
                                        )} 
                                        </div>
                                      );
                                    }
                                  )}                                
                               </div>                
                            </Card.Body>                                                  
                        </div>
                      </Card>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        )}
      </div>
    );
  }
}

export default Reminder;
