import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './assets/css/reset.css';
import './assets/css/normalize.css';
import './index.css';

/** Bootstrap components **/
import 'bootstrap/dist/css/bootstrap.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/js/bootstrap.bundle.js';

import App from './App';


import * as serviceWorker from './serviceWorker';

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const bootstrap = require('bootstrap');

ReactDOM.render(
  <>   
    < BrowserRouter>
     <App />
    </BrowserRouter>
     
  </>,
  document.getElementById('root')
);


/*** Page dom functions */
(function($) {

  // Add active state to sidbar nav links
  var path = window.location.href; // because the 'href' property of the DOM element is the absolute path
      $("#layoutSidenav_nav .sb-sidenav a.nav-link").each(function() {
          if (this.href === path) {
              $(this).addClass("active");
          }
      });

  // Toggle the side navigation
$("#sidebarToggle").click(function(){
  $("aside").toggle();
  });
  

  $("#sidebarToggle").on("click", function(e) {
      e.preventDefault();
      $("body").toggleClass("sb-sidenav-toggled");
      $("#sidebarToggle").toggleClass("closeSideBarMenu");
  });
  
  //var rect = getOffset(document.getElementById("pageFooterContent")).top;
  //var rect = $("#pageFooterContent").offset().top + 10;


  //$("aside#leftBar").css("height",rect);

  /* $(window).resize(function() {
    var rect = $("#pageFooterContent").offset().top + 10;
    $("aside#leftBar").css("height",rect);
  }); */
  
})($);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
