import Swal from 'sweetalert2'
import axios from "axios";

export const TriggerAlert = (success,errormessage,icon) => {
  if (errormessage == 'User not Authorised.'){
    setTimeout(axios.post("/admin/logout/", {}),7000)
    // window.location.reload();
  }
  else{
   return  Swal.fire({
    // position: 'center',
    icon: 'error',
    title: 'Oops...',
    backdrop:false,
    html: '<i style="color:red" class="fa fa-close"></i> <small>'+errormessage+"</small>",
    showConfirmButton: false,
    timer: 5000,
    showCloseButton: true,
  })
}
};

export const AlertMessage= (message,icon) => {
  
   return  Swal.fire({
    // position: 'center',
    icon: `${icon}`,
    title: 'Oops...',
    backdrop:false,
    html:'<span style="color:red">'+message +'</span>',
    showConfirmButton: false,
    timer: 5000,
    showCloseButton: true,
  })

};
export const ComplianceAlertMessage= (message) => {
  
  return  Swal.fire({

     icon: "success",
   title: '',
   backdrop:false,
   html:'<span style="color:red">'+message +'</span>',
   showConfirmButton: false,
   timer: 5000,
   showCloseButton: true,
 })
 

};

export const SuccessComplianceAlertMessage= (message) => {
  
  return  Swal.fire({

     icon: "success",
   title: '',
   backdrop:false,
   html:'<span style="color:green">'+message +'</span>',
   showConfirmButton: false,
   timer: 5000,
   showCloseButton: true,
 })
 

};

export const WarningMessage= (title,message) => {
  
  return  Swal.fire({
   // position: 'center',
   icon: "warning",
   title: `${title}`,
   backdrop:false,
   html:'<small>'+message +'</small>',
   showConfirmButton: false,
   timer: 5000,
   showCloseButton: true,
 })

};

export const ConfirmationMessage=(title,text,confirmtext)=>{
  return  Swal.fire({
    
      title: `${title}`,
      text: `${text}`,
      icon: 'warning',
      showConfirmButton:true,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: `${confirmtext}`,
  })

}



