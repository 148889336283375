import React, { Component } from 'react';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import UserService from '../services/user.service';
import '../assets/css/weatherWidget.css';
import AuthService from '../services/auth.service';
import lightrain from '../assets/img/lightrain.png';
import mediumrain from '../assets/img/mediumrain.png'
import heavyrain from '../assets/img/heavy_rain.png'
import heavyrainpng from '../assets/img/heavyrainpng.png'
import sunnyIcon from '../assets/img/sunnyicon.png'


class WeatherWidgetValueRender extends Component{

       constructor(){
        super()
        this.state={
            displayText:'',
        }
       }
    




    render(){      

        var weatherForecastBg = "forecastField";
        var weatherIcon = "";
      
        
       
        console.log('srianthnax', this.props.alert)

        if(this.props.data['Rainfall (mm)'] > 0  && this.props.data['Rainfall (mm)'] <= 0.09){
            weatherForecastBg =weatherForecastBg + " forecastCloudyContainer";
            weatherIcon = "cloudy";
            this.state.displayText = 'Cloudy'
         }
        else if(this.props.data['Rainfall (mm)'] > 0.1  && this.props.data['Rainfall (mm)'] <= 15.5){
            weatherForecastBg =weatherForecastBg + " forecastCloudyContainer";
            weatherIcon = {backgroundImage: `url(${lightrain})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', height: '90px',};
           this.state.displayText = 'Light Rain'
            
        }else if(this.props.data['Rainfall (mm)'] > 15.6  && this.props.data['Rainfall (mm)'] <= 64.4){
            weatherForecastBg = weatherForecastBg + " forecastRainyContainer ";
            weatherIcon = {backgroundImage: `url(${mediumrain})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', height: '90px', marginLeft:'15px', marginBottom:'5px'};
           this.state.displayText = ' Medium Rain'
        }else if (this.props.data['Rainfall (mm)'] > 64.5  && this.props.data['Rainfall (mm)'] <= 100  ){
            weatherForecastBg =
             weatherForecastBg + " forecastStarryContainer";
             weatherIcon = {backgroundImage: `url(${heavyrainpng})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', height: '90px',marginLeft:'15px'};
             this.state.displayText = 'Heavy Rain'
        } else{
            weatherForecastBg = weatherForecastBg+" forecastSunnyContainer";
            weatherIcon = {backgroundImage: `url(${sunnyIcon})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', height: '90px',marginLeft:'13px'};
            this.state.displayText = 'Sunny'
        }


        // if(this.props.alert !== "No warning"){
        //     if (this.props.alert === "Sunny") {
        //         weatherForecastBg =
        //             weatherForecastBg + " forecastThunderStormContainer";
        //         weatherIcon = "stormy";
        //     } else if (this.props.alert === "Sunny") {
        //         weatherForecastBg = weatherForecastBg + " forecastSunnyContainer";
        //         weatherIcon = "sunny";
        //     } else if (this.props.alert === "Heavy Rain") {
        //         weatherForecastBg =
        //             weatherForecastBg + " forecastRainyContainer ";
        //         weatherIcon = "rainy";
        //     } else if (this.props.alert === "Cloudy") {
        //         weatherForecastBg =
        //             weatherForecastBg + " forecastCloudyContainer";
        //         weatherIcon = "cloudy";
        //     } else if (this.props.alert === "Clear") {
        //         weatherForecastBg =
        //             weatherForecastBg + " forecastStarryContainer";
        //         weatherIcon = "starry";
        //     } else if (
        //         this.props.alert === "Heavy Rain|Thunderstorm & Lightning"
        //     ) {
        //         weatherForecastBg =
        //             weatherForecastBg + " forecastThunderStormContainer";
        //         weatherIcon = "stormy";
        //     }
        //     else if (
        //         this.props.alert ===
        //         "Heavy Rain|Thunderstorm & Lightning|Strong Surface Winds"
        //     ) {
        //         weatherForecastBg =
        //             weatherForecastBg + " forecastThunderStormContainer";
        //         weatherIcon = "stormy";
        //     }
           
        //     else {
        //           weatherForecastBg =
        //             weatherForecastBg + " forecastThunderStormContainer";
        //           weatherIcon = "stormy";
        //     }
        // }
        // else{
        //     weatherForecastBg = weatherForecastBg+" forecastSunnyContainer";
        //     weatherIcon = "sunny"
        // }
            
        return (
          <div className="weatherWidgetContainer">
            <div className={weatherForecastBg}>
              <div className={typeof weatherIcon == 'string' ? weatherIcon : ""} style = {typeof weatherIcon == 'object'? weatherIcon: {} }></div>
              
             <div className="weatherValHolder" style = {{position: 'absolute', top: '0'}} >
              <span style={{  position:'relative', left:'21px', top:'89px',  fontSize:'14px', backgroundSize: 'contain'}}><b>{ this.state.displayText}</b></span>
              
             <div style={{position:'relative', left:'165px', bottom:'16PX', fontSize:'14px',backgroundSize: 'contain' }}>
             <b >{this.props.data['Date']}</b>
             </div>
              
                <div style={{position:'relative', left:'165px', top:'4px',  fontSize:'14px', backgroundSize: 'contain'}} >Max: {this.props.data['Max. Temp. (℃)']}&deg; C</div>           
              <div style={{ position:'relative', left:'165px', top:'15px', fontSize:'14px', backgroundSize: 'contain'}} >Min: {this.props.data['Min. Temp. (℃)']}&deg; C</div>            
                
              
                
                        <span className="weatherAlert" style={{fontSize:"14px"}}>{''}</span>
                
                
                <span className="clearFixing"></span>
              </div>
            </div>
          </div>
        );
    }

}

class MainWeatherWidgetRendering extends Component{
    constructor(props){
        super(props)
        this.state = {
            weatherForecastData: []
        }
    }
    render(){
        return (
            <div className="mainWeatherWidgetContainer">
                <FontAwesomeIcon icon={faArrowCircleLeft} className="slideWeatherWidget"/>
                    {
                        this.props.data != undefined && this.props.data.filter(ele=>ele['Date'] != 'Total / Average').map(WEATHER_ALERT => {
                            // console.log('sunnyyyyy', weatherAlertAfterCheck)
                            let weatherAlertAfterCheck = "";
                            if(WEATHER_ALERT.alert !== "No warning")
                                weatherAlertAfterCheck = WEATHER_ALERT.alert;
                            else
                            weatherAlertAfterCheck = "rainy";
                            return (
                                

                                <WeatherWidgetValueRender key={WEATHER_ALERT.id} 
                                date={WEATHER_ALERT.date} MaxTemp={WEATHER_ALERT.MaxTemp}
                                MinTemp={WEATHER_ALERT.MinTemp}
                                alert = {weatherAlertAfterCheck}
                                data = {WEATHER_ALERT}
                                  />
                            );
                        })
                    }
                <FontAwesomeIcon icon={faArrowCircleRight} className="slideWeatherWidget"/>
            </div>
        );
    }

    // componentDidMount(){
    //     const user = AuthService.getCurrentUser();
    
    //     UserService.getWeatherForecast(user.state_id, user.district_code, user.blockId).then(
    //         (response) => {
    //           console.log('weatherforcast', response.data)
    //           if (response.data.length !== 0) {
    //             this.setState({
    //               weatherForecastData: response.data,
    //             });
    //           } 
    //         },
    //         (error) => {
    //           this.setState({
    //             content:
    //               (error.response &&
    //                 error.response.data &&
    //                 error.response.data.message) ||
    //               error.message ||
    //               error.toString(),
    //           });
    //         }
    //       );
    //  }


}

/*function weatherWidgetMethod() {
    return (
        <div className="container">
            <div className="weatherWidgetContainer testBord">
                <div className="forecastSunnyContainer forecastPresentDayIcon">
                    <div className="cloudy inline-block">

                    </div>
                </div>
            </div>
        </div>
    )
}*/

export default MainWeatherWidgetRendering;