import React, { Component, forwardRef, createRef } from "react";
import UserService from "../services/user.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMap,
  faMapMarker,
  faMapMarkerAlt,
  faMobileAlt,
  faTimes,
  faSave,
  faExclamationTriangle,
  faTrash,
  faHome,
  faArrowLeft,
  faDownload,
  faUpload,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import AuthService from "../services/auth.service";
import MaterialTable from "material-table";
import "../assets/css/soiltesting.css";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {
  Container,
  Card,
  Alert,
  Row,
  Col,
  ProgressBar,
  ProgressBarProps,
  ToggleButtonGroup,
  ToggleButton,
  Form,
  Modal,
  ModalBody,
  ModalDialog,
  ModalFooter,
  ModalProps,
  ModalTitle,
  ModalDialogProps,
  Accordion,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import moment from "moment";
import { TriggerAlert } from "./dryfunctions";
import { WarningMessage } from "./dryfunctions";
import {SuccessComplianceAlertMessage } from "./dryfunctions";
import { ConfirmationMessage } from "./dryfunctions";
import Swal from "sweetalert2";
import CloseIcon from '@material-ui/icons/Close';
import axios from "axios";
import { Grid ,TextField } from "@material-ui/core";

export default class FarmersListContent extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = createRef();
   
    this.state = {
      showloader: true,
      isParentLogged: "",
      isReportHistoryOpen: false,
      soil_report: "",
      soil_report_date: "",
      user_type: "",
      soil_types_list:[],
      soil_type:'',
      file_name: "",
      file_preview:false,
      file_url:  null,
      units_list:[{id:'Kg/Hectare',name:'Kg/Hectare'},{id:'Kg/Acre',name:'Kg/Acre'}],
      nitrogen:'',
      phosphorus:'',
      potassium:'',
      soil_ph:'',
      organic_carbon:'',
      fertilizer:'',
      validFileExtensions:['.png','.jpg','.pdf'],
      soil_report_missing:false,
      soil_report_date_missing:false,
      


  




    };
  }
  navigateMainBoard = () => {
    const { isParentLogged } = this.state;
    if (isParentLogged) {
      this.props.history.push("/fpohomeData");
    } else {
      this.props.history.push("/dashboard");
    }
  };
  updateSoilReports = () => {
    var flag = false;
    console.log(this.state.site_id, "my site id");
    UserService.getSoilData(this.state.site_id).then(
      (response) => {
        flag = true;
        console.log(response);

        if (response.data.success) {
          this.setState({
            showloader: false,
            SoilReportDetails: response.data.data,
          });
        } else {
          this.setState({
            showloader: false,
          });
          if (response) {
            TriggerAlert("Error", response.data.message, "error");
          } else {
            TriggerAlert(
              "Error",
              "Server closed unexpectedly, Please try again",
              "error"
            );
          }
        }
      },
      (error) => {
        flag = true;
        console.log("error");

        if (error.response) {
          TriggerAlert("Error", error.response.data.message, "error");
        } else {
          TriggerAlert(
            "Error",
            "Server closed unexpectedly, Please try again",
            "error"
          );
        }
      },
      setTimeout(() => {
        if (flag == false) {
          TriggerAlert("Error", "Response Timed out, Please try again", "info");
          this.navigateMainBoard();
        }
      }, 30000)
    );
  };
  setSoilTypes=()=>{
    UserService.getSoilTypesList(this.state.site_id).then(
      (response) => {
        console.log(response)
        this.setState({
          soil_types_list: response.data.data,
          soil_type_selected: response.data.data[0].id,
        });
      },
      (error) => {
        this.setState({
          showloader: false,
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
        if (error.response) {
          TriggerAlert(
            "Error",
            error.response.data.message.replace(".", " ") + "at Soil Type List",
            "error"
          );
        } else {
          TriggerAlert(
            "Error",
            "Server closed unexpectedly, Please try again",
            "error"
          );
        }
      }
    );
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();
    const fpoId = localStorage.getItem("fpoId");

    if (!user) {
      this.props.history.push("/");
      return;
    }
    if (user.is_parent) {
      this.setState({ isParentLogged: true });
    }
    if (user.is_farmer) {
      this.setState({
        user_type: "Farmer",
      });
    } else if (user.is_fr) {
      this.setState({
        user_type: "FR",
      });
    } else if (user.is_supervisor) {
      this.setState({
        user_type: "FPO",
      });
    }
   

    const urlString = window.location.pathname;
    const cleanedString = urlString.replace(/%20/g, " ");
    console.log(cleanedString.split("/"));
    const [, , farmerName, siteId, siteName] = cleanedString.split("/");
    this.setState(
      {
        farmer_name: farmerName,
        site_id: siteId,
        site_name: siteName,
      },
      () =>{
         this.updateSoilReports()
         this.setSoilTypes()
      }
    );
  }

  toggleReportHistory = () => {
    this.setState((prevState) => ({
      isReportHistoryOpen: !prevState.isReportHistoryOpen,
    }));
  };

  

  handleSoilReport = (e) => {
    const file = e.target.files[0];
    console.log(file);
    const fileType = file.type;
    const allowedFileTypes = ["image/jpeg", "application/pdf", "image/png",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];

    if (!allowedFileTypes.includes(fileType)) {
      e.target.value = null;
      this.setState({
        soil_report: '',
        file_name: '',
        
        
      });

      WarningMessage(
        "Unsupported file format",
        "Please upload supported file formats only!"
      );
    } else {
      this.setState({
        soil_report: e.target.files[0],
        file_name: file.name,
        soil_report_missing:false,
        
      });
    }
  };

  downloadSoilReport = (url) => {
    const link = document.createElement("a");
    link.href = url;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  deleteSoilReport = async (siteId, reportId) => {
    var flag = false;
    console.log(this.state.site_id, "my site id");
    var flag = false;
    const val = await ConfirmationMessage(
      "Are you sure?",
      "You will not be able to recover this file!",
      "Yes, delete it!"
    );
    console.log(val.isConfirmed);
    if (val.isConfirmed) {
      UserService.deleteReport(siteId, reportId).then(
        (response) => {
          flag = true;

          if (response.data.success) {
            this.updateSoilReports();

            SuccessComplianceAlertMessage("Deleted Succesfully");
          } else {
            if (response) {
              TriggerAlert("Error", response.data.message, "error");
            } else {
              TriggerAlert(
                "Error",
                "Server closed unexpectedly, Please try again",
                "error"
              );
            }
          }
        },
        (error) => {
          flag = true;
          if (error.response) {
            TriggerAlert("Error", error.response.data.message, "error");
          } else {
            TriggerAlert(
              "Error",
              "Server closed unexpectedly, Please try again",
              "error"
            );
          }
        },
        setTimeout(() => {
          if (flag == false) {
            TriggerAlert(
              "Error",
              "Response Timed out, Please try again",
              "info"
            );
            this.navigateMainBoard();
          }
        }, 30000)
      );
    } else {
      return;
    }
  };

  getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

   toPascalCase = (sentence) => sentence
   .toLowerCase()
   .replace(new RegExp(/[-_]+/, 'g'), ' ')
   .replace(new RegExp(/[^\w\s]/, 'g'), '')
   .trim()
   .split(' ')
   .map(word => word[0]
   .toUpperCase()
   .concat(word.slice(1)))
   .join(' ');

   handleFilepreview=(url)=>{
    
    this.setState({
      file_preview:true,
      file_url:url,

    },()=>{
      console.log(this.state.file_url,'updated url')
    });

   

   }

   closeFile=()=>{
    this.setState({
      file_preview:false,
      file_url:null,
      
    });

   }

   createSoilTypesOptions = (soilTypesList) =>
   soilTypesList.length
     ? soilTypesList.map((data) => (
         <option key={data.id} name={data.master_value} value={data.id}>
           {data.master_value}
         </option>
       ))
     : "";


     createFertilizerUnitsOptions = (unitsList) =>
   unitsList.length
     ? unitsList.map((data) => (
         <option key={data.id} name={data.name} value={data.id}>
           {data.name}
         </option>
       ))
     : "";

     



  handleSubmit = () => {
    const payload = new FormData();
    const { soil_report, soil_report_date, user_type,nitrogen,fertilizer,
      phosphorus,potassium,organic_carbon,soil_ph,soil_type
    } = this.state;
    if (soil_report != "" && soil_report_date != "" && user_type != "") {
      if((nitrogen!=''||phosphorus!=''||potassium!='')&&fertilizer==''){
        WarningMessage("Insufficient Data", "Submit the units for Land Area");
       
        return;

      }
      payload.append("soil_report", soil_report);
      payload.append("soil_report_date", soil_report_date);
      payload.append("user_type", user_type);
      payload.append('fertilizer',fertilizer);
      payload.append('nitrogen',nitrogen);
      payload.append('phosphorus',phosphorus);
      payload.append('potassium',potassium);
      payload.append('organic_carbon',organic_carbon);
      payload.append('soil_ph',soil_ph);
      payload.append('soil_type',soil_type);
     console.log(payload)
      var flag = false;
      UserService.uploadSoilReport(payload, this.state.site_id).then(
        (response) => {
          flag = true;
          console.log("Uploaded Successfully");
          if (response.data.success) {
            SuccessComplianceAlertMessage("Uploaded Succesfully");
            this.updateSoilReports();
            this.fileInputRef.current.value = null;
            this.setState({
              soil_report: "",
              soil_report_date: "",
              file_name: "",
             soil_type:'',
             nitrogen:'',
             phosphorus:'',
             potassium:'',
            soil_ph:'',
             organic_carbon:'',
             fertilizer:'',
             soil_report_date_missing:false,
             soil_report_missing:false,
            
            });
          } else {
            if (response) {
              TriggerAlert("Error", response.data.message, "error");
            } else {
              TriggerAlert(
                "Error",
                "Server closed unexpectedly, Please try again",
                "error"
              );
            }
          }
        },
        (error) => {
          flag = true;
          console.log(error.response);
          if (error.response) {
            TriggerAlert("Error", error.response.data.message, "error");
          } else {
            TriggerAlert(
              "Error",
              "Server closed unexpectedly, Please try again",
              "error"
            );
          }
        },
        setTimeout(() => {
          if (flag == false) {
            TriggerAlert(
              "Error",
              "Response Timed out, Please try again",
              "info"
            );
            this.navigateMainBoard();
          }
        }, 30000)
      );
    } else {
      if((nitrogen!=''||phosphorus!=''||potassium!='')&&fertilizer==''){
        WarningMessage("Insufficient Data", "Please Submit all the mandatory details and Land area units ");
        
        
         
      }
      else{
      WarningMessage("Insufficient Data", " Please Submit all the mandatory details");
      }
      if(soil_report_date==''){
        this.setState({
          soil_report_date_missing:true,
        })
        

      }
      if(soil_report==''){
        this.setState({
          soil_report_missing:true,
        },()=>console.log(this.state.soil_report_missing,'my misiing'))
        

      }
      
    }
  };

  handleFieldChange=(e)=>{
  
    this.setState({
      [e.target.name]:e.target.value
    },()=>console.log(this.state))
  }
  handleSoilReportDateChange=(e)=>{
  
    this.setState({
      [e.target.name]:e.target.value,
      soil_report_date_missing:false
    },()=>console.log(this.state))
  }

  



  handleFertlizerFieldChange=(e)=>{
    const regex = /^\d{0,5}(\.\d{0,2})?$/;
    console.log(regex.test(e.target.value),e.target.value);
    if (regex.test(e.target.value)||e.target.value==null||e.target.value=='') {
      this.setState({
        [e.target.name]:e.target.value ,
      });
    } 
  }

  handleSoilPhChange=(e)=>{
    const regex = /^(?:[1-9]|1[0-3]|14)(?:\.\d{0,2})?$/;

    if (regex.test(e.target.value)||e.target.value==null||e.target.value=='') {
      this.setState({
        [e.target.name]:e.target.value ,
      });
    } 

  }

  navigateToPage=(pageName)=>{
    this.props.history.push('/'+pageName+"");
  }

  


  render() {
    const user = AuthService.getCurrentUser();

    const {
      SoilReportDetails,
      soil_report_date,
      site_name,
      file_name,
      showloader,
      farmer_name,
      soil_types_list,
      soil_type,
      units_list,
      soil_report,
      site_id,
      user_type,
      file_preview,
      file_url,
      nitrogen,
      phosphorus,
      potassium,
      organic_carbon,
      soil_ph,
      fertilizer,
      validFileExtensions,
      soil_report_date_missing,
      soil_report_missing,
     

    } = this.state;
    const FarmerName=(farmer_name!=undefined)?this.toPascalCase(farmer_name):'';
    const SiteName=(site_name!=undefined)?this.toPascalCase(site_name):'';
    const tableIcons = {
      Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => (
        <DeleteOutline {...props} ref={ref} />
      )),
      DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
      )),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} ref={ref} />
      )),
      ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} ref={ref} />
      )),
      ViewColumn: forwardRef((props, ref) => (
        <ViewColumn {...props} ref={ref} />
      )),
    };
    const columns = [
      {
        title: "Sl.No",
        field: "serial",

        render: (rowData) => SoilReportDetails.indexOf(rowData)+1,
        cellStyle: {
          width: "3%",
        },
      },
      {
        title: "Soil Sample Report Date",
        field: "soil_report_date",

        render: (rowData) =>
          moment(rowData.soil_report_date).format("YYYY-MM-DD"),
        cellStyle: {
          width: "10%",
        },
      },

      {
        title: "Soil Type",
        field: "soil_type__master_value",
        render:(rowData)=>{
          return(<div>
         { rowData.soil_type__master_value==null?'NA':rowData.soil_type__master_value}
            </div>)
       },
        cellStyle: {
          width: "20%",
        },
      },
      {
        title: "Soil PH",
        field: "soil_ph",
        render:(rowData)=>{
          return(<div>
         { rowData.soil_ph==null?'NA':rowData.soil_ph}
            </div>)
       },
        cellStyle: {
          width: "8%",
        },
      },
      {
        title:'Organic Carbon',
        field:'organic_carbon',
        render:(rowData)=>{
          return(<div>
         { rowData.organic_carbon==null?'NA':rowData.organic_carbon}
            </div>)
       },
       cellStyle: {
        width: "10%",
      },
      },
      {
        title:'Fertilizer',
        field:'fertilizer',
        render:(rowData)=>{
          return(<div className="flexColumn">
        <div>NPK : { (rowData.fertilizer==null||rowData.fertilizer=='')?'NA':rowData.fertilizer}</div> 
        <div>N : { (rowData.nitrogen==null||rowData.nitrogen=='')?'NA':rowData.nitrogen}</div>
        <div>P : { (rowData.phosphorus==null||rowData.phosphorous=='')?'NA':rowData.phosphorus}</div>
        <div>K : { (rowData.potassium==null||rowData.potassium=='')?'NA':rowData.potassium}</div>
            </div>)
       },
       headerStyle:{
     width:'20%'
       },
       cellStyle: {
        width: "20%",
      },

      },

      {
        title: "Uploaded Date",
        field: "created_at",
        render: (rowData) => moment(rowData.created_at).format("YYYY-MM-DD"),
        defaultSort: "desc",

        cellStyle: {
          width: "10%",
        },
      },
      {
        title: "Uploaded By",
        field: "uploaded_by",

        cellStyle: {
          width: "8%",
        },
      },
      {
        title: `Reports View/
        Download${user.is_supervisor ? `/ Delete` : ""}`,
        field: "soil_report_pdf_presigned_url",

        render: (rowData) => {
          return (
            <div className="download_view_delete">
              <div>
              <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="button-tooltip-2">
                             <small> Download report</small>
                            </Tooltip>
                          }
                        >
                <FontAwesomeIcon
                  icon={faDownload}
                  className="dvaraBrownText hover "
                  style={{ fontSize: "1rem" }}
                  onClick={() =>
                    this.downloadSoilReport(
                      rowData.downloadurl
                    )
                  }
                />
                </OverlayTrigger>
              </div>
              <div>
              <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="button-tooltip-2">
                              <small>View report</small>
                            </Tooltip>
                          }
                        >
                  <FontAwesomeIcon
                    icon={faEye}
                    className="dvaraBrownText hover "
                    style={{ fontSize: "1rem" }}
                    onClick={ () =>{
                      
                  this.handleFilepreview(rowData.soil_report_pdf_presigned_url)
                    }
                  }
                  />
                  </OverlayTrigger>
                
              </div>
              {user.is_supervisor && (
                <div>
                <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="button-tooltip-2">
                             <small> Delete report</small>
                            </Tooltip>
                          }
                        >
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="dvaraBrownText hover "
                    style={{ fontSize: "1rem" }}
                    onClick={() =>
                      this.deleteSoilReport(this.state.site_id, rowData.id)
                    }
                  />
                  </OverlayTrigger>
                </div>
              )}
            </div>
          );
        },
        cellStyle: {
          width: "5%",
        },
      },
    ];
    console.log(this.state.file_url,this.state.file_preview,'this the url')
    return (
     
      <div className="wrap">
       <>{file_preview && (
        <div className='view_div'>
            <div className='close-div'>
                <CloseIcon className='dvaraWhite pointer' onClick={()=>this.closeFile()}/>
            </div>
           <div className="embeddedFile">
            {validFileExtensions.some(extension => file_url.includes(extension))?<embed src={file_url}  
             height='100%'
             width={file_url.includes('pdf')?'100%':'auto'} 
             
              ></embed> :
          
          <iframe  src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(file_url)}`} width="100%" height="100%"  >
      
     </iframe>}
     </div>
     </div>
       
    )}
      
        <div className="wrap">
          <div className="breadcrumb pageBreadCrumbHolder landHoldingBreadCrumbWrap">
            <a
              href="#"
              className="breadcrumb-item pageBreadCrumbItem"
              onClick={() => this.navigateMainBoard()}
            >
              <FontAwesomeIcon
                icon={faHome}
                className="dvaraBrownText breadcrumb-separator pageBreadCrumbItem"
                style={{ fontSize: "0.7rem" }}
              />
              &nbsp;Dashboard
            </a>
           
          </div>
        </div>
        
        <div className="SoilTestingMainSection">
        <div className="card_shadow Center">
        
        <div className="header marginBottom  dvaraWhite ">
          <h4 className=" SoilTestingHeading ">
            Soil Testing Report  
          </h4>
          <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="button-tooltip-2">
                              close
                            </Tooltip>
                          }
                        >
          <CloseIcon className="pointer" onClick={() => this.navigateToPage("landholding")}/>
          </OverlayTrigger>
        </div>
        <div className="paddingLeft paddingRight">
          <Row >
            <Col sm={6} className="dvaraBrownText">
              <b>Farmer Name : </b> {FarmerName}
            </Col>

            <Col sm={6} className="dvaraBrownText">
              <b>Site Name : </b> {SiteName}
            </Col>
          </Row>
          </div>
         
        
        <div className="SoilTestingUploadSection">
        <Grid container className="justifyContentSpaceBetween">
        <Grid container lg={5} sm={12}>
        <Grid container className="paddingTop paddingBottom">
        <Grid item sm={6} lg={6}> <label className='dvaraBrownText bold '>Soil Sample Report Date* :</label>
        </Grid>
        <Grid item sm={6} lg={6}>
                    
                    <input
                  type="date"
                  className={`inputFields ${soil_report_date_missing?'mandatory':''}`}
                  name='soil_report_date'
                  max={this.getCurrentDate()}
                  value={soil_report_date}
                  onChange={this.handleSoilReportDateChange}
                ></input>
                  </Grid>
                 
                  </Grid>
                  <Grid container className="paddingTop">
                  <Grid item sm={6} lg={6}> <label className="dvaraBrownText bold">Select Soil Type :</label></Grid>

                  <Grid item sm={6} lg={6}>
                  <Form>
                  <Form.Group >
                                    {/* <Form.Label className="dvaraBrownText">
                                      <b>Select Soil Type</b>
                                     
                                    </Form.Label> */}
                                    <Form.Control
                                    className="inputFieldsDropdown smallFont"
                                      as="select"
                                      size="sm"
                                      name='soil_type'
                                     value={soil_type}
                                       custom
                                       onChange={this.handleFieldChange}
                                    >
                                    
                                      <option value=''  >
                                       --Select SoilType--
                                      </option>
                                    
                                      {this.createSoilTypesOptions(
                                       soil_types_list
                                      )}
                                    </Form.Control>
                                    
                                  </Form.Group>
                                  </Form>
                  </Grid>
                  </Grid>
                  <Grid container className="paddingTop paddingBottom">
                  <Grid item sm={6} lg={6}><label className="dvaraBrownText bold">Organic Carbon :</label></Grid>
                  <Grid item sm={6} lg={6}>
                    <input
                type="text"
                placeholder="Enter Organic Carbon"
                  className="inputFields "
                 name='organic_carbon'
                 value={organic_carbon}
                  onChange={this.handleFertlizerFieldChange}
                ></input>
                  </Grid>
                  </Grid>

        </Grid>

        <Grid container  lg={5} sm={12}>
        <Grid container className="paddingTop paddingBottom">
        <Grid item sm={6} lg={6}><label className='dvaraBrownText bold '> Upload Report* : <small>(PDF/Excel/Word/Img)</small></label></Grid>
        <Grid item sm={6} lg={6}>
                    
                    <input
                  type="file"
                  className={`inputFields  fileUpload `}

                  id="fileInput"
                  ref={this.fileInputRef}
                  onChange={this.handleSoilReport}
                />
                <label htmlFor="fileInput" className={`inputFieldContainer ${soil_report_missing?'mandatory':''}`} >
                  <div className={`fileNameSoilTesting verticalAlign  `} style={{textAlign:'center'}}>
                   <small> {file_name != "" ? file_name : "Choose File"}</small>
                  </div>

                  <FontAwesomeIcon
                    icon={faUpload}
                    style={{ fontSize: "1rem" }}
                    className="dvaraBrownText  verticalAlign"
                  ></FontAwesomeIcon>
                </label>

                   
                  </Grid>
                  </Grid>
                  <Grid container className="paddingBottom">
                  <Grid item sm={6} lg={6}> <label className="dvaraBrownText bold">Soil pH
                 <small> (Range 1 to 14) </small>:</label></Grid>
                  <Grid item sm={6} lg={6}>
                   
                   
                    <input
                type="type" 
                  className="inputFields "
                  placeholder="Enter Soil pH"
                  max={14}
                  min={0}
                  name='soil_ph'
                  value={soil_ph}
                  onChange={this.handleSoilPhChange}
                ></input>
                  </Grid>
                  </Grid>
                  <Grid container className="paddingTop">
                  <Grid item sm={6} lg={6}> <label className="dvaraBrownText bold">Land Area <small>(in Units) </small>:</label></Grid>
                  <Grid item sm={6} lg={6}>
                  <Form.Group >
                                    {/* <Form.Label className="dvaraBrownText">
                                      <b>Fertilizer</b>
                                     
                                    </Form.Label> */}
                                    <Form.Control
                                    className={`inputFieldsDropdown smallFont ${((nitrogen!=''||phosphorus!=''||potassium!='')&&fertilizer=='')?'mandatory':''}`}
                                      as="select"
                                      size="sm"
                                      name="fertilizer"
                                      value={fertilizer}
                                      custom
                                     onChange={this.handleFieldChange}
                                    >
                                      <option value="" >
                                        --Select Units--
                                      </option>
                                       {this.createFertilizerUnitsOptions(
                                        units_list
                                      )} 
                                    </Form.Control>
                                   
                                  </Form.Group>
                                  </Grid>
                                  </Grid>
                  


        </Grid>
        <Grid container className='justifyContentSpaceBetween paddingTop paddingBottom fertilizerSection'  >
        <Grid item lg={2}  sm={6}>
                    <label className="dvaraBrownText bold justifyContentCenter">Nitrogen (N)</label>
                   
                    <input
                type='text'
                
                name='nitrogen'
                placeholder="Enter N value"
                  className="inputFields  justify_Content"
                 
                  value={nitrogen}
                  onChange={this.handleFertlizerFieldChange}
                ></input>
                 <label className="justifyContentCenter"><small>{fertilizer}</small></label>
                  </Grid> 
                  <Grid item lg={2} sm={6}>
                    <label className="dvaraBrownText bold justifyContentCenter">Phosphorous (P)</label>
                    
                    <input
               type='text'
                  className="inputFields "
                 name='phosphorus'
                 placeholder="Enter P value"
                 value={phosphorus}
                  onChange={this.handleFertlizerFieldChange}
                ></input>
                <label className="justifyContentCenter"><small>{fertilizer}</small></label>
                  </Grid> 
                  <Grid item lg={2} sm={6}>
                    <label className="dvaraBrownText bold justifyContentCenter">Potassium (K)</label>
                    
                    <input
                type='text'
                  className="inputFields "
                  name='potassium'
                 placeholder="Enter K value"
                 value={potassium}
                  onChange={this.handleFertlizerFieldChange}
                ></input>
                 <label className="justifyContentCenter"><small>{fertilizer}</small></label>
                  </Grid> 
                 
        </Grid>
        <Grid Container className='justifyContentSubmit' >
                    
                    <Button  onClick={this.handleSubmit} className="submitSoilReport ">
                  Submit
                </Button>
                 
                  </Grid>
        </Grid>
        </div>
        
        </div>
        <div className="Center">
        <Accordion defaultActiveKey={null}>
            <Accordion.Toggle
              eventKey="ReportHistory"
              className="triggerReportHistory dvaraWhite"
              onClick={this.toggleReportHistory}
            >
              {this.state.isReportHistoryOpen
                ? `Close Report History`
                : `Open Report History`}{this.state.isReportHistoryOpen?<ArrowDropUpIcon/>:<ArrowDropDownIcon/>}
            </Accordion.Toggle>
            <Accordion.Collapse
              eventKey="ReportHistory"
              onEntering={() => {
                this.setState({ isReportHistoryOpen: true });
              }}
              onExiting={() => {
                this.setState({ isReportHistoryOpen: false });
              }}
            >
              {showloader ? (
                <div className="mainCropsFarmerLoaderWrap">
                  <span className="spinner-border spinner-border-sm mainCropsFarmerLoader"></span>
                </div>
              ) : (
                <MaterialTable
                  icons={tableIcons}
                  title={
                    <h5 className="dvaraBrownText">
                      
                      Soil Sample Report History 
                    </h5>
                  }
                  data={SoilReportDetails}
                  columns={columns}
                  options={{
                    maxBodyHeight: 600,
                    actionsColumnIndex: -1,
                    search: true,
                    pageSize: 10,
                    pageSizeOptions: [
                      10, 20, 50, 100,
                     
                    ],

                    headerStyle: {
                      backgroundColor: "#A3C614",
                      color: "#fff",
                      fontSize: "1.2rem",
                      fontFamily: "barlow_reg",
                      fontWeight:'bolder',
                      textAlign:'center',
                      align:'center'
                      
                    },

                    rowStyle: {
                      backgroundColor: "#f1f1f1",
                      borderBottom: "2px solid #e2e2e2",
                      fontSize: "0.9rem",
                    },
                  }}
                />
              )}
            </Accordion.Collapse>
          </Accordion>
</div>

                  </div>
        </>

      </div>
      
    );
  }
}
